import React from 'react';
import Container from '@material-ui/core/Container';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  makeStyles,
  Typography,
  Box,
  MenuList,
  MenuItem,
  CardHeader,
  Button,
} from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
  },
  media: {
    height: 140,
  },
  cardTitles: {
    fontWeight: 700,
  },
  link: {
    boxShadow: 'none',
  },
});

export default function Todo() {
  const classes = useStyles();

  return (
    <Box my={4}>
      <Container maxWidth="md">
        <Grid container spacing={2} justify="space-evenly">
          <Grid item xs>
            <Card className={classes.root}>
              <CardActionArea
                component={Link}
                className={classes.link}
                to="/lyrics"
              >
                <CardMedia
                  className={classes.media}
                  // image="/static/images/cards/contemplative-reptile.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h2"
                    className={classes.cardTitles}
                  >
                    Lyrics
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs>
            <Card className={classes.root}>
              <CardActionArea
                component={Link}
                className={classes.link}
                to="/chords"
              >
                <CardMedia
                  className={classes.media}
                  // image="/static/images/cards/contemplative-reptile.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h2"
                    className={classes.cardTitles}
                  >
                    Chords
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <Button
                      to="/lyrics-by-artist"
                      component={Link}
                      className={classes.link}
                      variant="outlined"
                    >
                      All
                    </Button>
                  }
                  title="Top Artists' Lyrics"
                />
                <CardContent>
                  <MenuList>
                    <MenuItem
                      component={Link}
                      to="/lyrics-by-artist/w-d-amaradewa"
                      className={classes.link}
                    >
                      Amaradewa Master
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/lyrics-by-artist/gunadasa-kapuge"
                      className={classes.link}
                    >
                      Gunadasa Kapuge
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/lyrics-by-artist/h-r-jothipala"
                      className={classes.link}
                    >
                      H R Jothipala
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/lyrics-by-artist/nanda-malani"
                      className={classes.link}
                    >
                      Nanda Malini
                    </MenuItem>
                  </MenuList>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <Button
                      to="/chords-by-artist"
                      component={Link}
                      className={classes.link}
                      variant="outlined"
                    >
                      All
                    </Button>
                  }
                  title="Top Artists' Chords"
                />
                <CardContent>
                  <MenuList>
                    <MenuItem
                      component={Link}
                      to="/chords-by-artist/clarence-wijewardane"
                      className={classes.link}
                    >
                      Clarence Wijewardane
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/chords-by-artist/rookantha-gunathilaka"
                      className={classes.link}
                    >
                      Rookantha Gunathilaka
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/chords-by-artist/bathiya-and-santhush-bn-s"
                      className={classes.link}
                    >
                      BNS
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/chords-by-artist/priya-sooriyasena"
                      className={classes.link}
                    >
                      Priya Sooriyasena
                    </MenuItem>
                  </MenuList>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
